<template>
	<v-container fluid>
		<div v-if="!codigoContrato" class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small @click="getContratos">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-dialog max-width="1000">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" class="ma-1" color="primary" small>
						<v-icon left>mdi-chart-bar</v-icon>Contratos por mes
					</v-btn>
				</template>
				<v-card class="pa-2">
					<contratos-por-mes />
				</v-card>
			</v-dialog>
			<v-btn v-if="$root.acceso('VER_FACTURAS_COLABORADORES')" class="ma-1" color="primary" small to="/contratos/calculadora_comisiones">
				<v-icon left>mdi-abacus</v-icon>Calculadora flexible
			</v-btn>
			<!-- <v-btn class="ma-1" color="primary" small @click.stop="getContratosProximaRenovacion">
				<v-icon left>mdi-reload</v-icon>próxima renovación
			</v-btn>-->

			<v-btn
				v-for="filtro in Object.keys(estadosFiltros)"
				:key="filtro"
				small
				text
				:class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
				:color="getSituacionIcon(filtro).color"
				@click.stop="estadosFiltros[filtro] = !estadosFiltros[filtro]"
				:disabled="contratos.filter(con => con.Situacion == filtro).length == 0"
			>
				<v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
				{{ contratos.filter(con => con.Situacion == filtro).length }} {{ filtro }}
			</v-btn>

			<v-spacer />
			<v-btn
				v-if="$root.acceso('CAMBIAR_ESTADOS_CONTRATO')"
				@click="cambiarEstado = true"
				text
				color="primary"
				class="mr-1"
				:disabled="selected.length == 0"
			>Cambiar estado</v-btn>
			<v-btn
				v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS')"
				@click.stop="exportarTodos(selected)"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>Descargar seleccionados
			</v-btn>
		</div>
		<div v-if="!codigoContrato && $root.acceso('CREAR_CONTRATO')" class="pa-0">
			<v-card-subtitle class="pa-0">
				Los contratos nuevos se cargan en
				<router-link
					style="color: var(--v-secondary-base); text-decoration: none"
					to="/borradores"
				>borradores</router-link>
			</v-card-subtitle>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="contratosFiltrados"
				:headers="contratosHeader"
				show-select
				class="fixed-checkbox"
				item-key="CodigoContrato"
				v-model="selected"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="contratosFiltrados" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.Situacion="{ item }">
					<status-chip :value="item.Situacion" />
					<v-tooltip v-if="Number(item.amenazaCorte) && !Number(item.cortado)" bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="ml-1" v-bind="attrs" v-on="on" icon color="error darken-2">
								<v-icon>mdi-content-cut</v-icon>
							</v-btn>
						</template>
						Bajo amenaza de corte
					</v-tooltip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								:to="`/facturas?codigoContrato=${item.CodigoContrato}`"
								color="secondary"
								icon
							>
								<v-badge
									color="error darken-2"
									overlap
									:value="Boolean(Number(item.nImpagados))"
									:content="item.nImpagados"
								>
									<v-icon>mdi-paperclip</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>Ver facturas</span>
					</v-tooltip>
					<v-tooltip bottom v-if="$root.acceso('INICIAR_FIRMAS_CONTRATO')">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								@click="verEvidenciaFirma(item.CodigoContrato)"
								:disabled="
									!!gettingCert ||
									['Pendiente de Firma', 'Anulado'].includes(item.Situacion)
								"
								:loading="
									gettingCert == item.CodigoContrato ||
									['Generando certificado'].includes(item.Situacion)
								"
							>
								<v-icon>mdi-file-certificate-outline</v-icon>
							</v-btn>
						</template>
						<span>Ver certificado firma</span>
					</v-tooltip>
					<template v-if="$root.acceso('INICIAR_FIRMAS_CONTRATO')">
						<v-tooltip
							bottom
							v-if="
								!Boolean(Number(item.firmaSMS)) &&
								!Boolean(Number(item.firmaEmail))
							"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn disabled icon v-on="on" v-bind="attrs">
									<v-icon color="secondary">mdi-file-document-edit-outline</v-icon>
								</v-btn>
							</template>
							<span>Firmado manualmente</span>
						</v-tooltip>
						<v-tooltip bottom v-else-if="Boolean(Number(item.firmaSMS))">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									color="secondary"
									icon
									@click="reenviarFirmaSMS(item)"
									:disabled="
										!!reenviandoSMS ||
										!['Pendiente de Firma', 'Firma no válida'].includes(
											item.Situacion
										)
									"
									:loading="reenviandoSMS == item.CodigoContrato"
								>
									<v-icon>mdi-message-processing-outline</v-icon>
								</v-btn>
							</template>
							<span>Reenviar firma SMS</span>
						</v-tooltip>
						<v-tooltip bottom v-else-if="Boolean(Number(item.firmaEmail))">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									color="secondary"
									icon
									@click="recordatorioEmail(item)"
									:disabled="
										!!reenviandoSMS ||
										!['Pendiente de Firma', 'Firma no válida'].includes(
											item.Situacion
										)
									"
									:loading="reenviandoSMS == item.CodigoContrato"
								>
									<v-icon>mdi-email-edit-outline</v-icon>
								</v-btn>
							</template>
							<span>Enviar recordatorio firma Email</span>
						</v-tooltip>
					</template>
					<v-menu offset-x v-if="$root.acceso('INICIAR_FIRMAS_CONTRATO')">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								:loading="reenviandoSMS == item.CodigoContrato"
							>
								<v-icon>mdi-dots-vertical</v-icon>
							</v-btn>
						</template>
						<v-list dense>
							<v-list-item
								:disabled="
									Boolean(Number(item.firmaSMS)) ||
									Boolean(Number(item.firmaEmail))
								"
								@click.stop="reenviarFirmaSMS(item)"
							>
								<v-list-item-icon>
									<v-icon>mdi-message-processing-outline</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Iniciar firma SMS</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item
								:disabled="
									Boolean(Number(item.firmaSMS)) ||
									Boolean(Number(item.firmaEmail))
								"
								@click.stop="enviarFirmaEmail(item)"
							>
								<v-list-item-icon>
									<v-icon>mdi-email-edit-outline</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Iniciar firma email</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-tooltip bottom v-if="false && $root.acceso('DEV')">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								@click="generarPDF(item)"
								:disabled="generandoPDF != null"
								:loading="generandoPDF == item.CodigoContrato"
							>
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</template>
						<span>Generar PDF</span>
					</v-tooltip>
					<v-tooltip bottom v-if="$root.acceso('VER_INCIDENCIAS')">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="error"
								icon
								:to="`/incidencias?idContrato=${item.IdContrato}&codigoContrato=${item.CodigoContrato}`"
							>
								<v-badge
									bordered
									color="error darken-2"
									overlap
									:value="Boolean(Number(item.incidencias))"
									:content="item.incidencias"
								>
									<v-icon>mdi-alert-circle</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>Ver{{ $root.acceso('CREAR_INCIDENCIAS') ? " / Crear" : "" }} Incidencias</span>
					</v-tooltip>
					<template v-if="$root.acceso('VER_FACTURAS_COLABORADORES') && false">
						<v-tooltip v-if="item.facturado === '0'" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									color="secondary"
									:disabled="!['Activado', 'Baja'].includes(item.Situacion)"
									icon
								>
									<v-icon>mdi-account-cash-outline</v-icon>
								</v-btn>
							</template>
							<span>Facturado manualmente</span>
						</v-tooltip>
						<v-tooltip v-else-if="item.facturado === '-1' || item.comisionable === '0'" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									:disabled="!['Activado', 'Baja'].includes(item.Situacion)"
									icon
								>
									<v-icon>mdi-cash-remove</v-icon>
								</v-btn>
							</template>
							<span>No autofacturable</span>
						</v-tooltip>
						<v-tooltip v-else-if="item.facturado != null" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									color="secondary"
									:disabled="!['Activado', 'Baja'].includes(item.Situacion)"
									icon
									:to="`/autofacturacion?idFactura=${item.facturado}`"
								>
									<v-icon>mdi-currency-eur</v-icon>
								</v-btn>
							</template>
							<span>Facturado</span>
						</v-tooltip>
						<v-tooltip v-else-if="item.facturado === null && item.comisionable === '1'" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on"
									v-bind="attrs"
									color="secondary"
									:disabled="!['Activado', 'Baja'].includes(item.Situacion)"
									icon
									to="/autofacturar"
								>
									<v-icon>mdi-cash-register</v-icon>
								</v-btn>
							</template>
							<span>Sin facturar</span>
						</v-tooltip>
					</template>
					<v-tooltip bottom v-if="$root.acceso('DEV')">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								@click="generarPDF(item)"
								:loading="generandoPDF == item.CodigoContrato"
								:disabled="generandoPDF"
							>
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</template>
						<span>GENERAR PDF</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								:disabled="item.CodigoContratoRoot == null"
								@click="
									('=' + item.CodigoContratoRoot) == inlineFilters.CodigoContrato ?
										$delete(inlineFilters, 'CodigoContrato') :
										$set(inlineFilters, 'CodigoContrato', '=' + item.CodigoContratoRoot)
								"
							>
								<v-icon
									v-if="('=' + item.CodigoContratoRoot) == inlineFilters.CodigoContrato"
								>mdi-link-variant-off</v-icon>
								<v-icon v-else>mdi-link-variant</v-icon>
							</v-btn>
						</template>
						<span>Contrato original</span>
					</v-tooltip>
				</template>

				<template v-slot:item.comision="{ item }">
					<v-chip
						:class="`${item.comision < 0 ? 'error' : 'secondary'}--text v-chip--active`"
						small
						pill
						outlined
						:color="item.comision < 0 ? 'error' : 'secondary'"
						v-if="item.comision != null && (item.CodigoContratoRoot == null || item.CodigoContratoRoot == item.CodigoContrato)"
						:disabled="! ['Activado', 'Baja', 'Trámite', 'Baja por modificación'].includes( item.Situacion )"
					>{{ item.comision | eur }}</v-chip>
					<v-tooltip v-if="!Number(item.pagada) && !Number(item.liquidado) && item.TarifaGrupo.includes('FLEXIBLE') && (item.CodigoContratoRoot == null || item.CodigoContratoRoot == item.CodigoContrato)" bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								@click.stop="regenerarComision(item.CodigoContrato)"
								small
								icon
								color="secondary"
							>
								<v-icon small>mdi-reload</v-icon>
							</v-btn>
						</template>
						Regenerar comisión
					</v-tooltip>

					<v-btn
						icon
						small
						color="secondary"
						@click.stop="calculadoraComisionesModel = item.CodigoContrato"
						v-if="item.TarifaGrupo.includes('FLEXIBLE')"
					>
						<v-icon>mdi-abacus</v-icon>
					</v-btn>

				</template>

				<template v-slot:item.impagos="{ item }">
					<v-icon small v-if="item.impagos == '0'" color="secondary">mdi-currency-eur</v-icon>
					<v-icon small v-else color="error">mdi-currency-eur-off</v-icon>
				</template>

				<template v-slot:item.pendienteRenovacion="{ item }">
					<v-icon small v-if="item.pendienteRenovacion == '0'">mdi-minus-circle-outline</v-icon>
					<v-icon small v-else color="secondary">mdi-timer-sand</v-icon>
					<small v-if="item.pendienteRenovacion == '1'">
						{{ timeAgo( parseDate( item.FechaVto, true ) ) }}
					</small>
				</template>

				<template v-slot:item.facturado="{ item }">
					<v-icon small v-if="item.facturado == '0'" color="error">mdi-close-circle-outline</v-icon>
					<v-icon small v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
				</template>

				<template v-slot:item.pagado="{ item }">
					<v-icon small v-if="item.pagado == '0'" color="error">mdi-close-circle-outline</v-icon>
					<v-icon small v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
				</template>

				<template v-slot:item.liquidado="{ item }">
					<v-icon small v-if="item.liquidado == 0">mdi-close-circle-outline</v-icon>
					<v-icon small v-else-if="item.liquidado && item.esDevolucion" color="error">mdi-currency-eur-off</v-icon>
					<v-icon small v-else-if="item.liquidado && !item.esDevolucion" color="success">mdi-currency-eur</v-icon>

					{{ item.idLiquidacion }}
				</template>

				<template v-slot:item.CodigoContrato="{ item }">
					<div class="d-flex">
						<table-button
							color="secondary"
							:tooltip="!Number(item.modificado) ? 'Detalles del contrato' : 'Detalles del contrato (modificado)'"
							:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
							:icon-right="!!Number(item.modificado) ? 'mdi-pencil-off' : null"
							:icon-left="item.CodigoContrato > 0 ? 'mdi-lightning-bolt' : 'mdi-fire'"
						>{{ Math.abs(Number(item.CodigoContrato)) }}</table-button>
					</div>
				</template>

				<template v-slot:item.Identificador="{ item }">
					<table-button
						color="secondary"
						tooltip="Detalles del cliente"
						:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
					>{{ item.Identificador }}</table-button>
				</template>

				<template v-slot:item.CodigoCUPS="{ item }">
					<table-button
						color="secondary"
						tooltip="Detalles del CUPS"
						:to="`/detalles_cups?cups=${item.CodigoCUPS}`"
					>{{ item.CodigoCUPS }}</table-button>
				</template>

				<template v-slot:item.FechaAlta="{ item }">
					<span v-text="parseDate(item.FechaAlta)"></span>
				</template>
				<template v-slot:item.FechaBaja="{ item }">
					<span v-text="parseDate(item.FechaBaja)"></span>
				</template>
				<template v-slot:item.FechaContrato="{ item }">
					<span v-text="parseDate(item.FechaContrato)"></span>
				</template>
				<template v-slot:item.FechaVto="{ item }">
					<span v-text="parseDate(item.FechaVto)"></span>
				</template>

				<template v-slot:item.Tarifa="{ item }">
					<v-chip v-show="item.Tarifa" label small v-text="item.Tarifa" />
				</template>

				<template v-slot:item.TarifaGrupo="{ item }">
					<span
						v-if="item.TarifaGrupo && !(item.TarifaGrupo).includes('FLEXIBLE')"
						v-text="item.TarifaGrupo.split(' ($')[0]"
					/>
					<v-tooltip v-else bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="copiarTexto(item.TarifaGrupo)"
								small
								text
								color="secondary"
								v-on="on"
								v-bind="attrs"
								class="pa-0"
							>Flexible</v-btn>
						</template>
						<span v-text="item.TarifaGrupo" />
					</v-tooltip>
				</template>

				<template v-slot:item.DiasSinFacturar="{ item }">
					<v-chip
						class="mb-0 pa-2"
						small
						dense
						label
						:color="comprobarDiasFacturacion(item.DiasSinFacturar, item.Situacion).color"
					>{{ comprobarDiasFacturacion(item.DiasSinFacturar, item.Situacion).texto }}</v-chip>
				</template>

				<template v-slot:footer.prepend>
					<status-chip :value="$store.getters.getEntorno" />
				</template>
			</v-data-table>
		</v-card>

		<v-navigation-drawer
			width="900"
			absolute
			temporary
			touchless
			right
			:value="calculadoraComisionesModel"
			@input="(v) => v === false ? calculadoraComisionesModel = null : null"
			class="pa-2"
			:color="$vuetify.theme.dark ? '#1E1E1E' : '#f3f3f3'"
			:elevation="0"
		>
			<calculadoraComisiones :key="calculadoraComisionesModel" :ComisionContrato="(contratos.find(c => c.CodigoContrato == calculadoraComisionesModel) || {}).comision" :CodigoContrato="calculadoraComisionesModel" />
		</v-navigation-drawer>

		<div
			v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS') && !codigoContrato"
			class="d-flex flex-wrap mt-4"
			style="gap: 16px"
		>
			<v-btn text color="primary" @click="exportarTodos">
				<v-icon left>mdi-download</v-icon>Exportar todos
			</v-btn>
		</div>

		<v-dialog
			max-width="1200px"
			width="100%"
			@input="(v) => (v ? null : (documentacionModal = null))"
			:value="documentacionModal"
		>
			<v-card color>
				<v-toolbar dense color="primary">
					<v-toolbar-title class="white--text">No se ha firmado electrónicamente</v-toolbar-title>
					<v-spacer />
					<v-btn icon color="white" @click="documentacionModal = null">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<documentacion-contrato
					:key="documentacionModal + Math.random()"
					:codigoContrato="documentacionModal"
					@close="documentacionModal = false"
				/>
			</v-card>
		</v-dialog>

		<v-dialog persistent max-width="600px" width="100%" v-model="cambiarEstado">
			<cambiar-estado
				:key="cambiarEstado"
				@reload="getContratos"
				:contratos="selected.map((c) => c.CodigoContrato)"
				@close="cambiarEstado = false"
			/>
		</v-dialog>

		<portal-target name="filter-btn" />
	</v-container>
</template>

<script>
// import {axios} from '@/main.js';
import Chart from "@/components/charts/index.js";

import {
	getSituacionIcon,
	perColumnFilter,
	parseDate,
	downloadFile,
	jsonToCsv,
	timeAgo
} from "@/utils/index.js";

export default {
	components: {
		ContratosPorMes: () => import("../components/ContratosPorMes.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
		TableButton: () => import("@/components/TableButton.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		CalculadoraComisiones: () => import("./CalculadoraComisiones.vue"),
		CambiarEstado: () => import("../components/CambiarEstado.vue"),
		DocumentacionContrato: () =>
			import("../components/DocumentacionContrato.vue"),
    LineChart: Chart("LineChart"),
	},
	props: {
		codigoContrato: Number | String,
	},
	data() {
		return {
			calculadoraComisionesModel: null,
			debounce: true,
			loading: true,
			page: 2,
			selected: [],
			search: null,
			development: process.env.NODE_ENV == "development",
			contratos: [],
			cambiarEstado: false,
			contratosHeader: [
				{ text: "Situacion", value: "Situacion", dataType: "select" },
				{
					text: "Acciones",
					value: "acciones",
					filterable: false,
					sortable: false,
				},
				// { text: "EMAIL", value: "firmaEmail" },
				// { text: "SMS", value: "firmaSMS" },
				{ text: "Comisión", value: "comision", acceso: 'VER_FACTURAS_COLABORADORES' },
				// { text: "prioridad", value: "prioridad", acceso: 'VER_FACTURAS_COLABORADORES' },
				{ text: "Pdt. Renovacion", value: "pendienteRenovacion", dataType: 'bool', align: 'center', acceso: 'DEV' },
				{ text: "Impagos", value: "impagos", dataType: 'bool', align: 'center' },
				// { text: "Facturado", value: "facturado", acceso: 'VER_FACTURAS_COLABORADORES', dataType: 'bool', align: 'center' },
				// { text: "Pagado", value: "pagado", acceso: 'VER_FACTURAS_COLABORADORES', dataType: 'bool', align: 'center' },
				{ text: "Liquidado", value: "liquidado", acceso: 'VER_FACTURAS_COLABORADORES', dataType: 'bool', align: 'center' },
				{ text: "Mod", value: "modificado", acceso: 'DEV', dataType: 'bool', align: 'center' },
				{ text: "Canal", value: "Canal", cellClass: "pa-2 text-no-wrap font-weight-medium" },
				{ text: "Subagente", value: "SubAgente" },
				{ text: "Grupo Tarifa", value: "TarifaGrupo" },
				{ text: "Contrato", value: "CodigoContrato" },
				{ text: "DNI/NIE", value: "Identificador" },
				{ text: "Nombre", value: "Denominacion" },
				{ text: "CUPS", value: "CodigoCUPS" },
				{ text: "Direccion Cups", value: "DireccionCUPS" },
				// { text: "Ciudad Cups", value: "CiudadSuministro" },
				{ text: "Renovación", value: "renovacion", },
				{ text: "Fecha Alta", value: "FechaAlta", dataType: "date" },
				{ text: "Fecha Baja", value: "FechaBaja", dataType: "date" },
				{ text: "Fecha Vto", value: "FechaVto", dataType: "date" },
				{ text: "Fecha Contrato", value: "FechaContrato", dataType: "date" },
				{ text: "Tarifa", value: "Tarifa", dataType: "select" },
				{ text: "Consumo Anual", value: "ConsumoEstimado", align: "end", },
				{ text: "P1", value: "P1", align: "end", entorno: 'Luz' },
				{ text: "P2", value: "P2", align: "end", entorno: 'Luz' },
				{ text: "P3", value: "P3", align: "end", entorno: 'Luz' },
				{ text: "P4", value: "P4", align: "end", entorno: 'Luz' },
				{ text: "P5", value: "P5", align: "end", entorno: 'Luz' },
				{ text: "P6", value: "P6", align: "end", entorno: 'Luz' },
				{ text: "Caudal", value: "Caudal", align: "end", entorno: 'Gas' },
				// { text: "Dias sin facturar", value: "DiasSinFacturar", acceso: 'VER_FACTURAS_CONTRATOS' },
			].filter(header => (header.entorno == null || header.entorno == this.$store.getters.getEntorno) && (!header.acceso || this.$root.acceso(header.acceso))).map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) => perColumnFilter(value, this.inlineFilters[header.value], header.dataType),
					dataType: "text",
					...header,
				};
			}),

			estadosFiltros: {
				'Anulado': false,
			},

			inlineFilters: {},
			comboboxes: {},
			loadingPDF: null,
			gettingCert: null,
			reenviandoSMS: false,
			documentacionModal: null,
			generandoPDF: null,
		};
	},
	computed: {
		contratosFiltrados() {
			return this.contratos.filter((con) => {
				for (let [key, value] of Object.entries(this.estadosFiltros))
					if (con.Situacion == key) return value;
				return true;
			})
		},
	},
	methods: {
		getSituacionIcon,
		parseDate,
		timeAgo,
		getContratos() {
			this.loading = true;
			this.selected = [];
			let str = this.$store.getters.getDatosEmpresa;

			const { IdUsuario, IdAgente } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdAgente,
					codigoContrato: this.codigoContrato,
				},
			}).then((res) => {
				if (!Array.isArray(res.data)) res.data = [res.data]
				this.contratos = res.data.map((c) => {
					c.Denominacion= c.Denominacion || c.RazonSocialTitular || [c.NombreTitular, c.Apellido1Titular, c.Apellido2Titular] .filter((i) => !!i) .join(" ");
					c.DireccionCUPS= c.DireccionCUPS || [c.CiudadSuministro, c.CallePuntoSuministro, c.Numero] .filter((i) => !!i) .join(", ");
					c.ConsumoEstimado = parseFloat(c.ConsumoEstimado).toFixed(3) * 1;
					c.P1 = parseFloat(c.P1).toFixed(3) * 1 || null;
					c.P2 = parseFloat(c.P2).toFixed(3) * 1 || null;
					c.P3 = parseFloat(c.P3).toFixed(3) * 1 || null;
					c.P4 = parseFloat(c.P4).toFixed(3) * 1 || null;
					c.P5 = parseFloat(c.P5).toFixed(3) * 1 || null;
					c.P6 = parseFloat(c.P6).toFixed(3) * 1 || null;
					c.impagos = c.nImpagados > 0;
					// c.evolucionFacturas = c.evolucionFacturas != null ? c.evolucionFacturas.split('=>') : null
					return c
				});

				this.loading = false;
			});
		},
		verEvidenciaFirma(codContrato) {
			this.gettingCert = codContrato;
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/certificado_firma.php`,
				method: "GET",
				params: {
					token: this.$store.getters.getJwtEmpresa,
					codigoContrato: codContrato,
				},
				responseType: "arraybuffer",
			})
				.then(({ data }) => {
					let decoder = new TextDecoder();
					if (
						/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
							decoder.decode(data)
						)
					) {
						let a = document.createElement("a");
						a.href = decoder.decode(data);
						a.click();
					} else {
						let file = new File(
							[data],
							`certificado_firma_${codContrato}.pdf`,
							{
								type: "application/pdf",
							}
						);

						downloadFile(file, true);
					}
				})
				.catch((e) => {
					if (e.response.status == 401) {
						this.documentacionModal = codContrato;
					} else {
						this.$root.$emit(
							"snack",
							"No se ha podido recuperar el certificado"
						);
					}
					this.gettingCert = null;
					return;
				})
				.finally(() => {
					this.gettingCert = null;
				});
		},
		async reenviarFirmaSMS({ CodigoContrato }) {
			if (
				!confirm(
					`¿Estás seguro de que quieres reenviar la firma del contrato ${CodigoContrato}?, se enviará como contrato de ${this.$store.getters.getNombreEmpresa.toUpperCase()}`
				)
			)
				return;
			this.reenviandoSMS = CodigoContrato;

			try {
				await axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/enviar_firma_sms.php`,
					params: {
						codigoContrato: CodigoContrato,
						empresa: this.$store.getters.getNombreEmpresa,
						idContrato: CodigoContrato,
						token: this.$store.getters.getJwtEmpresa,
					},
				});
				this.$root.$emit("snack", "Se ha enviado el SMS de verificación");
			} catch (e) {
				console.error(e);
				this.$root.$emit(
					"snack",
					"No se ha podido enviar el SMS de verificación"
				);
			}

			this.reenviandoSMS = null;
		},
		async enviarFirmaEmail({ CodigoContrato }) {
			if (
				!confirm(
					`¿Estás seguro de que quieres iniciar la firma email del contrato ${CodigoContrato}?, se enviará como contrato de ${this.$store.getters.getNombreEmpresa.toUpperCase()}`
				)
			)
				return;
			this.reenviandoSMS = CodigoContrato;

			try {
				let { data: email_firma } = await axios({
					method: "GET",
					url: `https://pruebas.alumbraenergia.es/api/public/enviar_firma_email.php`,
					params: {
						codigoContrato: CodigoContrato,
						empresa: this.$store.getters.getNombreEmpresa,
						idContrato: CodigoContrato,
						token: this.$store.getters.getJwtEmpresa,
					},
				});
				this.$root.$emit("snack", "Se ha iniciado la firma email");
			} catch (e) {
				console.error(e);
				this.$root.$emit("snack", "No se ha podido enviar el email de firma");
			}
			this.reenviandoSMS = null;
		},
		async recordatorioEmail({ CodigoContrato }) {
			if (
				!confirm(
					`¿Estás seguro de que quieres enviar un recordatorio al contrato ${CodigoContrato}?`
				)
			)
				return;

			try {
				let { data: respuesta } = await axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/recordatorio_email.php`,
					params: {
						idContrato: CodigoContrato,
						token: this.$store.getters.getJwtEmpresa,
					},
				});
				if (respuesta.code != 200) throw respuesta.code;
				this.$root.$emit("snack", "Se ha enviado el recordatorio de firma");
			} catch {
				this.$root.$emit(
					"snack",
					"No se ha podido enviar el recordatorio de firma"
				);
			}

			this.reenviandoSMS = null;
		},
		regenerarComision(codigoContrato) {
			if (!this.$root.acceso('VER_COMISIONES_CONTRATOS')) return;
			let str = this.$store.getters.getDatosEmpresa;
			const { IdAgente } = str.iss;
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/altaProducto.php`,
				method: 'PUT',
				data: {
					CodigoContrato: codigoContrato,
					token: this.$store.getters.getJwtEmpresa,
					IdAgente
				}
			}).then(res => {
				this.$root.$emit('snack', 'La comisión se ha regenerado correctamente');
				// this.getContratos();
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'Ha ocurrido un error al regenerar la comisión');
			})
		},
		exportarTodos(items) {
			const getDatatype = (key) =>
				(this.contratosHeader.find((h) => h.value == key) || {}).dataType;

			if (!Array.isArray(items))
				items = this.contratos.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				);

			const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

			jsonToCsv(items, csvHeaders, 'CONTRATOS_' + parseDate(Date.now(), false, false));
		},
		generarPDF({ CodigoContrato }) {
			this.generandoPDF = CodigoContrato;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_OUR_API_URL}/generar_pdf.php`,
				params: {
					codigoContrato: CodigoContrato,
					empresa: this.$store.getters.getNombreEmpresa,
					// empresa: this.contratos.find((c) => c.id == this.imprimirId).empresa,
					token: this.$store.getters.getJwtEmpresa,
					// firma : firma || null
				},
				headers: { "Access-Control-Allow-Origin": location.origin },
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`borrador_${new Date().getTime()}.pdf`,
						{ type: "application/pdf" }
					);
					downloadFile(pdf, true);
					this.generandoPDF = null;
				})
				.catch((err) => {
					console.error(err);
					this.generandoPDF = null;
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
		copiarTexto(value) {
			const input = document.createElement('input')
			document.body.appendChild(input)
			input.value = value
			input.select()
			try {
				document.execCommand('copy');
				this.$root.$emit('snack', 'Copiado al portapapeles')
			} catch {
			}
			input.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()
			document.body.removeChild(input)
		}
	},
	mounted() {
		this.getContratos();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>